(function ($) {
    'use strict';

    var browserWindow = $(window);

    // Menu Active
    var CURRENT_PATH = window.location.pathname;
    CURRENT_PATH = CURRENT_PATH.replace(/\$/, "");
    CURRENT_PATH = decodeURIComponent(CURRENT_PATH);

    $('.classynav > ul > li').each(function() {
        if($(this).closest('li').hasClass('treeview')) {
            $(this).find('li').each(function() {
                var href = $(this).find('a').attr('href');
                if(href !== undefined && href !== '#' && CURRENT_PATH.substring(0, href.length) === href) {
                    $(this).parent().parent().addClass('active');
                }
            });
        } else {
            var href = $(this).find('a').attr('href');
            if(href !== undefined && href != '/' && href !== '#' && CURRENT_PATH.substring(0, href.length) === href) {
                $(this).closest('li').addClass('active');
            }
        }
    });

    $('.block-click').on('click', function() {
        $.blockUI();
    });

    // :: 1.0 Preloader Active Code
    browserWindow.on('load', function () {
        $('#preloader').fadeOut('slow', function () {
            $(this).remove();
        });
    });

    // :: 3.0 Nav Active Code
    if ($.fn.classyNav) {
        $('#newspaperNav').classyNav();
    }

    // :: 4.0 Gallery Active Code
    if ($.fn.magnificPopup) {
        $('.videoPlayer').magnificPopup({
            type: 'iframe'
        });
    }

    // :: 5.0 ScrollUp Active Code
    if ($.fn.scrollUp) {
        browserWindow.scrollUp({
            scrollSpeed: 1500,
            scrollText: '<i class="fa fa-angle-up"></i>'
        });
    }

    // :: 6.0 CouterUp Active Code
    if ($.fn.counterUp) {
        $('.counter').counterUp({
            delay: 10,
            time: 2000
        });
    }

    // :: 7.0 Sticky Active Code
    if ($.fn.sticky) {
        $("#stickyMenu").sticky({
            topSpacing: 0
        });
    }

    // :: 8.0 wow Active Code
    if (browserWindow.width() > 767) {
        new WOW().init();
    }

    // :: 9.0 prevent default a click
    $('a[href="#"]').click(function ($) {
        $.preventDefault()
    });

})(jQuery);